import React, { Component } from "react";

export class ScanMessage extends Component {
  render() {
    const { scanIcon, scanMessage } = this.props;

    return (
      <div className={"scan-message"}>
        {scanIcon && (
          <img src={scanIcon.contentUrl} alt={'scan'}/>
        )}
        <p>{scanMessage ? scanMessage : "Please scan your card"}</p>
      </div>
    );
  }
}

export default ScanMessage;
