import React, { Component } from "react";

// import "bootstrap/dist/css/bootstrap.min.css";

export class ComparableItem extends Component {
  render() {
    const { item, backgroundImageUrl } = this.props;

    return (
      <div className={"comparable-item col"}
           style={{ backgroundImage: "url(" + backgroundImageUrl + ")" }}>
        <div className="row justify-content-md-center">
          <div className="header col-auto">
            <h1 className={"title"}>{item.title}</h1>
            <h2 className={"subtitle"}>{item.subtitle}</h2>
          </div>
        </div>
        <div className="content">
          {item.image &&
          <div className="image">
            <img src={item.image.contentUrl} alt=""/>
          </div>
          }
          <div className="body" dangerouslySetInnerHTML={{ __html: item.body }}></div>
        </div>
        <div className="footer-container row justify-content-center no-gutters">
          <div className="col-auto">
            <div className={"footer"} dangerouslySetInnerHTML={{ __html: item.footer }}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComparableItem;
