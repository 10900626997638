import React, { Component } from "react";
import "./App.css";
import axiosInstance from "./axiosInstance";
import { newTag, lostTag } from "./services/tagDetector.js";
import _ from "lodash";
import ComparableItem from "./components/ComparableItem";
import "bootstrap/dist/css/bootstrap.min.css";
import ScanMessage from "./components/ScanMessage";

var NFCReadChars = [];

export class App extends Component {
  timeOutTimer = null;

  state = {
    isStandalone: true,
    deviceUID: window.location.pathname.slice(1) ? window.location.pathname.slice(1).split("/").pop() : window.localStorage.getItem("deviceUID"),
    device: null,
    config: null,
    queueList: {},
    productList: {},
    timeOutDuration: 60000,
    currentParticipant: null,
    showScanMessage: false,
  };

  resetTimeOut = () => {
    if (this.state.currentParticipant === null) {
      return;
    }
    clearTimeout(this.timeOutTimer);
    //TODO: get wallTimeout if not null else use 60000
    this.timeOutTimer = setTimeout(
      () => this.timeOut(),
      this.state.timeOutDuration
    );
  };

  timeOut = () => {
    console.log("timeout session expired");
    this.setState({ currentParticipant: null });
  };

  getConfig = () => {
    const { deviceUID } = this.state;
    if (typeof deviceUID !== "undefined" && deviceUID !== null) {
      this.setState({ deviceUID: deviceUID });
    }
    if (deviceUID) {
      axiosInstance.get("/devices/" + deviceUID).then(({ data: device }) => {
        if (device) {
          this.setState({ device });
          if (typeof device.comparatorConfig === "undefined" || device.comparatorConfig === null) {
            return;
          }
          axiosInstance.get(device.comparatorConfig).then(({ data: config }) => {
            if (config) {
              this.setState({ config });
              this.initMercureHub();
              this.activateNFCReader();
              const head =
                document.head || document.getElementsByTagName("head")[0],
                style = document.createElement("style");
              head.appendChild(style);

              if (style.type) {
                style.type = "text/css";
              }
              if (style.styleSheet) {
                // This is required for IE8 and below.
                style.styleSheet.cssText = config.cssStyle;
              } else {
                style.appendChild(document.createTextNode(config.cssStyle));
              }
            }
          });
        }
      });
    }
  };

  componentDidMount() {
    this.getConfig();

    newTag.subscribe(payload => {
      console.log("newTag payload:", payload);
      const { config } = this.state;
      console.log("config.compareBehavior:", config.compareBehavior);
      if (this.state.currentParticipant !== null) {
        this.resetTimeOut();
        if (config.compareBehavior === "drop") {
          this.displayItem(payload.tag_uid);
        } else {
          this.hideItem(payload.tag_uid);
        }
      } else {
        this.showScanMessage();
      }
    }, error => {
      console.log("error");
      console.log(error);
    });

    lostTag.subscribe(payload => {
      console.log("lostTag payload:", payload);
      const { config } = this.state;
      console.log("config.compareBehavior:", config.compareBehavior);
      if (this.state.currentParticipant !== null) {
        this.resetTimeOut();
        if (config.compareBehavior === "drop") {
          this.hideItem(payload.tag_uid);
        } else {
          this.displayItem(payload.tag_uid);
        }
      } else {
        this.showScanMessage();
      }
    }, error => {
      console.log("error");
      console.log(error);
    });
  }

  async initMercureHub() {
    const { device } = this.state;
    const url = new URL(process.env.REACT_APP_MERCURE_HUB_URL);
    const restartAppTopic = "device/" + device.deviceUID + "/RESTART_APP";
    const NFCReadTopics = [];
    url.searchParams.append("topic", restartAppTopic);
    if (device.deviceCircle != null) {
      if (typeof device.deviceCircle !== "object") {
        try {
          device.deviceCircle = (await axiosInstance.get(device.deviceCircle)).data;
        } catch (e) {
        }
      }
      this.setState({ device: device });
    }
    if (typeof device.deviceCircle !== "undefined" && device.deviceCircle !== null) {
      for (const d of device.deviceCircle.devices) {
        if (typeof d === "object" && d.type.slug === 'nfc-activator') {
          const t = "device/" + d.deviceUID + "/NFC_READ";
          NFCReadTopics.push(t);
          url.searchParams.append("topic", t);
        }
      }
    }
    this.eventSource = new EventSource(url);
    this.eventSource.onmessage = async e => {
      console.log(e);
      console.log("mercury event response: ", e.data);
      const payload = JSON.parse(e.data);
      if (NFCReadTopics.indexOf(payload.topic) > -1) {
        if (
          payload.data.participant !== null &&
          typeof payload.data.participant !== "undefined"
        ) {
          const person = payload.data.participant;
          const activateAction = { person: person["@id"], project: device.project, device: device["@id"] };
          axiosInstance({
            method: "POST",
            url: "/activate_actions",
            data: JSON.stringify(activateAction)
          });
          console.log("new participant activation");
          console.log(person);
          this.setState({ currentParticipant: person });
          this.resetTimeOut();
        } else {
          console.log("La carte n'est pas enregistré.");
        }
      } else if (payload.topic === restartAppTopic) {
        console.log("received " + restartAppTopic);
        console.log(payload);
        window.location.reload();
      } else {
        console.log("unhandled topic");
      }
    };
  }

  showScanMessage() {
    console.log("showScanMessage");
    this.setState({ showScanMessage: true });
    setTimeout(() => {
      this.setState({ showScanMessage: false });
    }, 10000);
  }

  activateNFCReader = () => {
    window.document.addEventListener("keyup", this.handleNFCReadKeyUp);
  };

  deactivateNFCReader = () => {
    window.document.removeEventListener("keyup", this.handleNFCReadKeyUp);
  };

  handleNFCReadKeyUp = (event) => {
    const keyCode = ("which" in event) ? event.which : event.keyCode;
    if (event.keyCode === 13) {
      let numStr = NFCReadChars.join("");
      NFCReadChars = [];
      console.log(numStr);
      const hex = Number(numStr).toString(16);
      //console.log(hex);
      // --------
      const listHex = [];
      const hexStr = hex.toString();
      for (var i = 0; i < hexStr.length; i++) {
        var tmpStr = String(hexStr).substr(i * 2, 2);
        listHex.push(tmpStr);
      }
      //const uidCard = "0x" + listHex.reverse().join("");
      const uidCard = listHex.reverse().join("");
      this.findPersonByUid(uidCard.toUpperCase());
      console.log("uidCard:", uidCard);
    } else {
      NFCReadChars.push(String.fromCharCode(keyCode));
    }
  };

  findPersonByUid = async (uidCard) => {
    try {
      var matches = this.state.device.project.match(/\d+/g);
      const participant = (await axiosInstance.get(
        `/people/${uidCard}/${matches[0]}`
      )).data;
      if (participant) {
        console.log("new participant activation");
        console.log(participant);
        this.setState({ currentParticipant: participant });
        this.resetTimeOut();
      }
    } catch (e) {
      console.log(e);
    }
  };

  displayItem = (tag) => {
    const { productList, queueList, config, currentParticipant, device } = this.state;
    const product = config.items.find(x => x.uid === tag);
    console.log("displayItem product:", product);
    if (!product) return;
    if ("undefined" === typeof productList[tag]) {
      if (_.size(productList) < config.maxVisibleItems) {
        productList[product.uid] = product;
      } else if (!_.has(queueList, product.uid)) {
        queueList[product.uid] = product;
      }
    }

    const compareAction = {
      person: currentParticipant["@id"],
      project: device.project,
      device: device["@id"],
      objects: []
    };
    Object.keys(productList).forEach(key => {
      compareAction.objects.push(productList[key]["@id"]);
    });
    axiosInstance({
      method: "POST",
      url: "/compare_actions",
      data: JSON.stringify(compareAction)
    });

    this.setState({ productList, queueList });
  };

  hideItem = (tag) => {
    const { productList, queueList } = this.state;
    if ("undefined" !== typeof productList[tag]) {
      delete productList[tag];
    }
    if (_.has(queueList, tag)) {
      delete queueList[tag];
    }
    const { config } = this.state;
    if (_.size(queueList) > 0 && _.size(productList) < config.maxVisibleItems) {
      for (const prop in queueList) {
        const product = queueList[prop];
        productList[product.uid] = product;
        delete queueList[product.uid];
        break;
      }
    }
    this.setState({ queueList, productList });
  };

  render() {
    const { config, deviceUID, productList, currentParticipant, showScanMessage } = this.state;
    console.log("productList:", productList);
    let productListArray = Object.values(productList);
    if (config && config.displayType === "by_position") {
      productListArray = productListArray.sort((a, b) =>
        typeof a.position !== "undefined" ? (a.position > b.position
          ? 1
          : a.position === b.position
            ? a.id > b.id
              ? 1
              : -1
            : -1) : 1
      );
    }
    const productListCount = Object.keys(productList).length;
    let backgroundImageUrl = "";
    if (productListCount === 1 && config.comparableItemSingleBackgroundImage) {
      backgroundImageUrl = config.comparableItemSingleBackgroundImage.contentUrl;
    } else if (productListCount > 1 && config.comparableItemBackgroundImage) {
      backgroundImageUrl = config.comparableItemBackgroundImage.contentUrl;
    }
    return (
      <div className="App" style={{
        "backgroundColor": config && config.backgroundColor !== null ? config.backgroundColor : "white",
        "backgroundImage": (config && config.backgroundImage !== null && (Object.keys(productList).length === 0 || currentParticipant === null)) ? "url(" + config.backgroundImage.contentUrl + ")" : ""
      }}>
        {deviceUID && (
          <div className="wrapper-container">
            {showScanMessage && currentParticipant === null && (
              <ScanMessage scanIcon={config.scanIcon} scanMessage={config.scanMessage}/>
            )}
            {currentParticipant !== null && (
              <div className={"row no-gutters items-container items-" + Object.keys(productList).length}
                   style={{ width: "100%", height: "100%" }}>
                {productListArray.map((item, key) => {
                  return (
                    <ComparableItem key={key} item={item} backgroundImageUrl={backgroundImageUrl}/>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default App;
